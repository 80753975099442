import React, { useState, useEffect } from "react";

const Press = () => {
    $(document).ready(function() {

        $(".age_btn").click(function(event) {
            event.preventDefault()
            $('.overlay').addClass('disappear');
            $('html,body').addClass('headerActive');
            $(".content-inside").addClass('disappear');
        })
    
        var clouds = $('.clound-image'),
            timeline = [];
        for (var clIndex = 0; clIndex < clouds.length; ++clIndex) {
          
            var mesure = clouds.length - 1,
                delay = -(mesure + 2 - clIndex),
                start = 1 * clIndex,
                end = +100 - 100 * (mesure - clIndex)
    
            timeline[clIndex] = TweenMax.fromTo($('#cl' + clIndex, ), 50, { transform: 'translateX(+' + start + 'vw)' }, { transform: 'translateX(' + end + 'vw)', ease: Linear.easeNone, repeat: -1 },
                delay
            );
    
        };
    
        var cloudOne = $('.clound-image'),
            timeline = [];
        for (var cloudIndex = 0; cloudIndex < cloudOne.length; ++cloudIndex) {
           
            var mesure = cloudOne.length - 1,
                delay = -(mesure + 2 - cloudIndex),
                start = 1 * cloudIndex,
                end = +100 - 100 * (mesure - cloudIndex)
    
            timeline[cloudIndex] = TweenMax.fromTo($('#cloud' + cloudIndex, ), 50, { transform: 'translateX(+' + start + 'vw)' }, { transform: 'translateX(' + end + 'vw)', ease: Linear.easeNone, repeat: -1 },
                delay
            );
    
        };
    
        var cloud = $('.clound-image2'),
            timeline = [];
        for (var cloudIndex = 0; cloudIndex < cloud.length; ++cloudIndex) {
          
            var mesure = cloud.length - 1,
                delay = -(mesure + 2 - cloudIndex),
                start = 1 * cloudIndex,
                end = -100 - 100 * (mesure - cloudIndex)
    
            timeline[cloudIndex] = TweenMax.fromTo($('#ml' + cloudIndex), 50, { transform: 'translateX(+' + start + 'vw)' }, { transform: 'translateX(' + end + 'vw)', ease: Linear.easeNone, repeat: -1 },
                delay
            );
        };
    
        $('ul.cards-split').on('click', function() {
            $(this).addClass('transition');
        });
    
    })
    
    useEffect(() => {
        $(document).ready( function() {
			$('.main-inside').addClass('activetitle');
		  });
          
        var sections = $('section')
    $(window).on('scroll', function() {
    var cur_pos = $(this).scrollTop();
    sections.each(function() {
        var top = $(this).offset().top -280,
            bottom = top + $(this).outerHeight();
        if (cur_pos >= top && cur_pos <= bottom) {
            //nav.find('a').removeClass('active');
            // sections.removeClass('active');
            $(this).addClass('activetitle');
        }
    });
    });
    }, []);
  return (
    <div className="prss_page">
      <section className="main-inside about-us">
        <div className="secion1 mb-20">
          <h1 className="h2 text-center site-color animatetext">
          PRESS
            </h1>
          <div className="v-line mb-60">
            <svg
              className="sc-16mwcw0-0 iZNjkc"
              width="2"
              height="283"
              viewBox="0 0 2 283"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 0L0.999988 283" stroke="#7E573E" strokeWidth="2" />
            </svg>
          </div>
        </div>
      </section>
      <section className="section7 page-section">

<div className="container relative mt-60">			
    <div className="row align-item-center column-row">
        <div className="column-4">
            <picture className="vscrolling vscroll position-relative d-block"><img src="images/pairing-image-1.png" alt="pairing" /></picture>
        </div>
        <div className="column-6">
              <div className="small-content-column_">
                <h3 className="site-color h5">THE MEN’S GIFT GUIDE</h3>
                <p>Idaaya got featured in Goop's 2023 Holiday Gift Guide</p>
            </div>
        </div>
    </div>
    <div className="row align-item-center column-row flex-row-reverse leftside">
        <div className="column-4">
            <picture className="vscrolling2 vscroll position-relative d-block"><img src="images/pairing-image-1.png" alt="pairing-image-1" /></picture>
        </div>
        <div className="column-6">
              <div className="small-content-column_">
                <h3 className="site-color h5">THE MEN’S GIFT GUIDE</h3>
                <p>Idaaya got featured in Goop's 2023 Holiday Gift Guide</p>
            </div>
        </div>
    </div>
    <div className="row align-item-center column-row">
        <div className="column-4">
            <picture className="vscrolling vscroll position-relative d-block"><img src="images/pairing-image-1.png" alt="pairing-image-1" /></picture>
        </div>
        <div className="column-6">
              <div className="small-content-column_">
                <h3 className="site-color h5">THE MEN’S GIFT GUIDE</h3>
                <p>Idaaya got featured in Goop's 2023 Holiday Gift Guide</p>
            </div>
        </div>
    </div>
    
</div>

<div className="mountain1 clound-image  cloud-left" id="cl1">
    <img src="images/cloud1.png" alt="cloud1" />
</div>
<div className="mountain2 clound-image2 cloud-right" id="ml0">
    <img src="images/cloud2.png" alt="cloud2" />
</div>
</section>

<section className="section8 page-section">

<div className="container relative leftside">	
    <div className="row align-item-center column-row flex-row-reverse">
        <div className="column-4">
            <picture className="vscrolling2 vscroll position-relative d-block"><img src="images/pairing-image-1.png" alt="pairing-image-1" /></picture>
        </div>
        <div className="column-6">
              <div className="small-content-column_">
                <h3 className="site-color h5">THE MEN’S GIFT GUIDE</h3>
                <p>Idaaya got featured in Goop's 2023 Holiday Gift Guide</p>
            </div>
        </div>
    </div>		
   <div className="py-60">
   <div className="v-line">
            <svg
              className="sc-16mwcw0-0 iZNjkc"
              width="2"
              height="283"
              viewBox="0 0 2 283"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 0L0.999988 283" stroke="#7E573E" strokeWidth="2" />
            </svg>
          </div>
   </div>
                
    
</div>

<div className="mountain1 clound-image  cloud-left" id="cloud1">
    <img src="images/cloud1.png" alt="cloud1" />
</div>
<div className="mountain2 clound-image2 cloud-right" id="ml1">
    <img src="images/cloud2.png" alt="cloud2" />
</div>		
</section>

    </div>
  );
};

export default Press;
