import React, { useRef, useEffect, forwardRef } from 'react';
import ImageSequenceCanvas from '../components/ImageSequenceCanvas';

const NoPage = () => {
   

    return (
        <div className='nopage_page'>
        
      <section className="main-inside mt-160vh">
		
		<div className="secion1 mb-60">
			<h1 className='text-center site-color'>404</h1>
			<h1 className='text-center site-color'>Page</h1>
		</div>
	</section>

	
        
        </div>
    );
};

export default NoPage;