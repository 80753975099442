import React from "react";

import Slider from "react-slick";
import OurrumBanner from "../components/OurrumBanner"
import "slick-carousel/slick/slick.css";

const Ourrum = () => {
 

  return (
    <div className="ourrum_page">
      	<OurrumBanner/>

   
    </div>
  );
};

export default Ourrum;
