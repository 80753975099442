import React, { useState, useEffect} from "react";

const TermsConditions = () => {
	useEffect(() => {
		
		$(document).ready( function() {
		  $('.terms_page').addClass('activetitle');
		});
	
		$(window).on('scroll', function() {
		  var cur_pos = $(this).scrollTop();
		  $('section').each(function() {
			var top = $(this).offset().top - 280,
			  bottom = top + $(this).outerHeight();
			if (cur_pos >= top && cur_pos <= bottom) {
			  $(this).addClass('activetitle');
			}
		  });
		});
	  }, []);

  return (
	<div className="termspage conditionpage">

	<div className="vertical_moutain_1 terms_moutain_1"><img src="images/side-mountains-1.png" alt="side mountain"/></div>
		<div className="vertical_moutain_2 terms_moutain_2"><img src="images/side-mountains-2.png" alt="side mountain right"/></div>		
		<section className="main terms_page about-us" id="container1">    	
    	<div className="main-content">
		    <div className="about-us-banner">		    	
		    	<div className="container2_">
		    		<h1 className="h2 site-color text-uppercase text-center">
					terms and <br/>conditions of use
						</h1>
		    	</div>
		    </div>
		</div>
	</section>
	<section className="termslat_sec">
		
		<div className="secion1">
			<div className="v-line small-line"> <svg
                className="sc-16mwcw0-0 iZNjkc"
                width="2"
                height="283"
                viewBox="0 0 2 283"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1 0L0.999988 283" stroke="#7E573E" strokeWidth="2" />
              </svg></div>
			<div className="">
				<div className="small-container">
					<p>Any use of this Site by any person (hereinafter "you" or the "User(s)") is governed by these terms & conditions of use (hereinafter the "Terms & Conditions"). The purpose of these Terms & Conditions is to define the conditions of access, navigation and use of the Site. By accessing and using the Site, you accept without reservation the application of these</p> 

<p>Terms & Conditions.<br/>
We reserve the right to modify and/or update these Terms & Conditions by publishing a new version on our Site. We therefore invite you to consult them regularly.<br/>
To access the Site, you must have reached the minimum legal age for the consumption and/or purchase of alcohol (whichever is higher) according to the legislation in force in your place of residence. If such legislation does not exist in your place of residence, you must be at least 21 years old to access the Site.</p>
					
				</div>
				
			</div>
		</div>
	</section>
	</div>
  );
};

export default TermsConditions;
