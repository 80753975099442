import React, { useState, useEffect } from "react";

const AboutUs = () => {
	
	useEffect(() => {
		$(document).ready( function() {
			$('.main-inside').addClass('activetitle');
		  });

		var sections = $('section')
$(window).on('scroll', function() {
    var cur_pos = $(this).scrollTop();
    sections.each(function() {
        var top = $(this).offset().top -300,
            bottom = top + $(this).outerHeight();
        if (cur_pos >= top && cur_pos <= bottom) {
            //nav.find('a').removeClass('active');
            // sections.removeClass('active');
            $(this).addClass('activetitle');
        }
    });
});

		const handleScroll = () => {
		  $(".mountain-section.active").each(function () {
			var windowTop = $(window).scrollTop();
			var elementTop = $(this).offset().top - 300;
			var mountainTopPosition = windowTop - elementTop;
			var mountainBottomPosition = windowTop - elementTop;
	
			$(this).find(".moutain_vscrolling").css({
			  transform: "translateY(-" + mountainTopPosition / 10 + "px)",
			});
	
			$(this).find(".moutain_vscrolling2").css({
			  transform: "translateY(" + mountainBottomPosition / 10 + "px)",
			});
		  });
		};
	
		// Attach the scroll event handler
		$(window).on("scroll", handleScroll);
	
		// Cleanup the event listener when the component unmounts
		return () => {
		  $(window).off("scroll", handleScroll);
		};
	  }, []);
   

  return (
    <div className="about_page">
    <div className="vertical_moutain_1 aboutus_mountain"><img src="images/side-mountains-1.png" alt="side mountain"/></div>
		<div className="vertical_moutain_2 aboutus_mountain"><img src="images/side-mountains-2.png" alt="side mountain right"/></div>

	<section className="main-inside about-us page-section">		
		<div className="secion1">
        <h1 className="h2 text-center site-color animatetext">
		ABOUT US
			</h1>
        <div className="v-line small-line">
				<svg className="sc-16mwcw0-0 iZNjkc" width="2" height="283" viewBox="0 0 2 283" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M1 0L0.999988 283" stroke="#7E573E" strokeWidth="2"/>
				</svg>
				</div>
			<div className="">
       
				<div className="text-center container3">
					<p>IDAAYA is a premium sipping rum crafted against the backdrop of the Himalayas.<br/> Created with the intent of promoting India’s heritage of spirit-making and our vast reserve <br/>
					of methodologies and ingredients, IDAAYA is rooted in a vision to present the best of India<br/> to global audiences. Our dark sipping rum is created from a blend of pure Indian rum and <br/>12-year-old rum aged in bourbon casks. Water from the snow-capped Himalayas,<br/> known for its therapeutic properties, is used in the making of our rum, which is blended in<br/> sal wood casks — a practice native to the Himalayan region of India —<br/>using the Solera system. 
</p>
				

				<p>Embodying the spirit of the Himalayas, IDAAYA is a culmination of passion <br/>for the craft of rum-making, a deep reverence for tradition <br/>and an aspiration towards uncompromising skill. </p>
					
				</div>
				
			</div>
		</div>
	</section>

	<section className="page-section about-section-2">
			<div className="container position-relative container2">
				<div className="logomark"><img src="images/logomark.png" alt="logomark" /></div>
				<div className="">
					<picture className="vscroll vscrolling2 d-block"><img src="images/about-image-1.png" alt="about-image1"/></picture>
				</div>

				<div className="right_image_about">
					<picture className="vscroll vscrolling d-block"><img src="images/about-image2.png" alt="about-image2"/></picture>
				</div>
			</div>
	</section>

	<section className="about-section-3 mountain-section page-section">	
		<div className="about-golden-mount">
		  	<picture className="vscroll d-block pic1"><img src="images/golden-mountain1.png" alt="golden-mountain1"/></picture>
		  	<picture className="vscroll d-block pic2"><img src="images/golden-mountain2.png" alt="golden-mountain2"/></picture>
		  </div>
		 <div className="snow-6"><img src="images/snow-6.png" alt="snow"/></div> 
		 <div className="pouring-image-1"><img src="images/pouring-image-1.gif" alt="pouring image-1"/></div>
		 <div className="pouring-image-2"><img src="images/pouring-image-2.gif" alt="pouring image-2"/></div>
		<div className="container relative">				
				<div className="small-container text-center">
				<section className="about-content-small-section">
					   <h2 className="h3 site-color text-center text-uppercase animatetext">
					   karishma manga bedi
						 
						</h2>
						<p className="text-center text-uppercase">Founder and CHIEF EVERYTHING OFFICER</p>
						<p>With over 20 years of experience in branding, strategy and business<br/>development across leading luxury global brands, including Dior,<br/>TAG Heuer and Sephora, Karishma has helped build many startups<br/>in India, and has decided to channel her love for discovery and<br/>curiosity towards this new entrepreneurial adventure.</p>
					</section>
					<section className="about-content-small-section">
					   <h2 className="h3 site-color text-center text-uppercase animatetext">
					   samrath bedi
						</h2>
						<p className="text-center text-uppercase">Director and Investor</p>
						<p>First generation entrepreneur credited with creating a first-of-its-kind<br/> luxury Ayurvedic skincare and beauty brand, Forest Essentials.<br/>With a zest for creating iconic brands with strong Indian roots,<br/>Sam has been the engine that has propelled the business, with over<br/> 175 company-owned stores in India, and a strong presence in the UK<br/> and Middle East. A connoisseur of dark spirits and wine,<br/>Sam’s refined taste is sought after by many.</p>
					</section>
					<section className="about-content-small-section">
					   <h2 className="h3 site-color text-center text-uppercase animatetext">
					   SHIVAM misra
						</h2>
						<p className="text-center text-uppercase">Founding Director</p>
						<p>With over 24 years of experience in the liquor industry in India and<br/> emerging global markets, Shivam has a breadth of experience across<br/> functions and geographies in leading organisations, building global<br/> iconic brands and crafting local heroes. His passion for fine spirits and<br/> the mountains found its calling with IDAAYA.</p>
					</section>
					
					
				</div>
		</div>
		
	</section>

	<section className="mountain-bg-section mountain-section position-relative">	
		<div className="light-snow"><img src="images/light-snow.png" alt="light snow"/></div>
	</section>


    </div>
  );
};

export default AboutUs;
