import React, { useRef, useEffect, useState } from "react";
import { useParallax } from "react-scroll-parallax";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { addClassesOnScroll } from '../js/addClassesOnScroll.js';
import splitH2IntoSpans from "../js/splitH2IntoSpans";
import $ from 'jquery';

const OurrumBanner = () => {


  const [isUserScrolling, setIsUserScrolling] = useState(false);
  const [scrolledDown, setScrolledDown] = useState(false);
  const isMobile = window.innerWidth <= 868;
  useEffect(() => {
    if (!isMobile) {
      addClassesOnScroll();
      window.addEventListener('scroll', handleScrollOnce);
    }
  
    return () => {
      window.removeEventListener('scroll', handleScrollOnce);
    };
  }, []);

  const handleScrollOnce = () => {
    if (!scrolledDown && window.scrollY > 20) { 
      splitH2IntoSpans();
      setScrolledDown(true);
      const header = document.querySelector('.header');
      if (header) {
        header.classList.add('fixed-header'); 
      }
      window.removeEventListener('scroll', handleScrollOnce); 
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const headerHeight = $('#rum').height();
      if (!isMobile) {
        if ($(window).scrollTop() >= headerHeight) {
          $('.header').removeClass('fixed-header').css('position', 'absolute');
        } else {
          $('.header').addClass('fixed-header').css('position', 'fixed');
        }
      } else {
        $('.header').removeClass('fixed-header').css('position', '');
      }
    };
  
    $(window).on('scroll', handleScroll);
  
    return () => {
      $(window).off('scroll', handleScroll);
      $('.header').removeClass('fixed-header').css('position', '');
    };
  }, [isMobile]);
  

  useEffect(() => {
    function handleScroll() {
      setIsUserScrolling(true);
      let timeout;
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setIsUserScrolling(false);
      }, 200);
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    function initializeSliderRum() {
      // Only initialize scrollify if it's not a mobile view
      if (!isMobile) {
        const options = {
          section: ".ourrumslide .panel",
          scrollSpeed: 500,
          scrollbars: true,
          updateHash: false,
          touchScroll: true,
          overflowScroll: true,
          setHeights: true,
          fade: true,
        };

        $.scrollify(options);
      }
    }

    const isOurRumPage = window.location.pathname === "/ourrum";

    if (isOurRumPage) {
      initializeSliderRum();
    }

    return () => {
      if (isOurRumPage && !isMobile) {
        try {
          $.scrollify.destroy();
        } catch (error) { }
      }
    };
  }, []);



  useEffect(() => {
    $('.home').addClass('activetitle');
  }, []);


  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const rootsBanner = document.querySelector('.ourrumslide');
      if (rootsBanner) {
        rootsBanner.classList.add('ourrum_active');
      }
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, []);



  useEffect(() => {
    var clouds = $('.clound-image'),
      timeline = [];
    clouds.each(function (clIndex) {
      var measure = clouds.length - 1,
        delay = -(measure + 2 - clIndex),
        start = 1 * clIndex,
        end = +100 - 100 * (measure - clIndex);
      timeline[clIndex] = TweenMax.fromTo($(this), 90, { transform: 'translateX(+' + start + 'vw)' }, { transform: 'translateX(' + end + 'vw)', ease: Linear.easeNone, repeat: -1 }, delay);
    });

    var clouds = $('.clound-image-left'),
      timeline = [];
    clouds.each(function (clIndex) {
      var measure = clouds.length - 1,
        delay = -(measure + 2 - clIndex),
        start = 1 * clIndex,
        end = +100 - 100 * (measure - clIndex);
      timeline[clIndex] = TweenMax.fromTo($(this), 90, { transform: 'translateX(+' + start + 'vw)' }, { transform: 'translateX(' + end + 'vw)', ease: Linear.easeNone, repeat: -1 }, delay);
    });


    // .clound-image2
    var cloud2 = $('.clound-image2'),
      timeline2 = [];
    cloud2.each(function (cloudIndex) {
      var measure = cloud2.length - 1,
        delay = -(measure + 2 - cloudIndex),
        start = 1 * cloudIndex,
        end = -100 - 100 * (measure - cloudIndex);
      timeline2[cloudIndex] = TweenMax.fromTo($(this), 90, { transform: 'translateX(+' + start + 'vw)' }, { transform: 'translateX(' + end + 'vw)', ease: Linear.easeNone, repeat: -1 }, delay);
    });

  }, []);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 9000,
    standardScrollElements: false,
    setHeights: true,
    overflowScroll: true,
    touchScroll: true,
    scrollbars: true,
    updateHash: false,
    fade: true,
  };

  return (
    <div className="main" id="container1">
      <div className="main-content home_sticky" id="container2">
        <section className="home">
          <div className="main-panel">
            <div id="scenes" className="our-rum-banner" data-relative-input="true"></div>
            <div className="slides ourrumslide" id="ourum">
              <div className="panel vh-fix">
                <div className="logomark">
                  <img src="images/logomark.svg" alt="logomark" />
                </div>
              </div>
              <div className="box-new text-white no-blend-mode">
                <h1 className="h4 text-white w-100 text-center mb-10 mt-60">A TASTE AS OLD AS TIME</h1>
              </div>
              <div className="panel vh-fix ourrum-slide1" id="slide1">

                <div className="box-new-1">
                  <div className="new-animation">
                    <h2 className="w-100">
                      Originating in the Himalayas,<br />our rum references traditional Indian <br />methodologies inspired by <br />THE ARTHASHASTRA, and the Solera system. <br />This time-honoured provenance,<br /> rooted in Indian culture, <br />is what forms the essence of IDAAYA.
                    </h2>
                  </div>

                </div>
              </div>
              <div className="panel vh-fix">
                <section className="main-inside our-rum" id="rum">
                  <div className="secion1">
                    <div className="v-line line-big">
                      <svg
                        className="sc-16mwcw0-0 iZNjkc"
                        width="2"
                        height="283"
                        viewBox="0 0 2 283"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 0L0.999988 283" stroke="#7E573E" strokeWidth="2" />
                      </svg>
                    </div>
                    <div className="">
                      <div className="text-center secion1_content">
                        <p>
                          Our dark sipping rum is created from a blend of pure Indian rum and 12-year-old rum aged in bourbon casks. Water from the snow-capped Himalayas, known for its therapeutic properties, is used in the making of our rum, which is blended in sal wood casks —a practice native to the Himalayan region of India — using the Solera system.
                        </p>

                        <p>
                          The result is a smooth, well-rounded rum that, at first sip, reveals notes of caramel, dark chocolate, jaggery and tropical fruits. It then slowly unravels complex hints of dried fruits, allspice, and toasted vanilla and oak.
                        </p>
                      </div>
                      <div className="text-center rum-glass position-relative text-uppercase">
                        <img src="images/rum-glass.png" alt="rum glass" />
                         
                      </div>
                    </div>
                  </div>
                </section>

                <section className="sorera-section page-section">
                  <div className="container position-relative">
                    <div className="small-content">
                      <p className="white-text">
                        A flavour that, with every sip, peels back layers of mystery, leaving you with the lingering taste of the Himalayas.
                      </p>
                      <p className="white-text font-20 text-uppercase">
                        Distinct.<br /> Unpretentious.<br /> Elevated.
                      </p>
                    </div>
                  </div>
                </section>

                <section className="section5 page-section">
                  <div className="container">
                    <div className="slider-container position-relative">
                      <div className="row align-item-end">
                        <div className="position-relative ourrum_textscroll">
                          <picture className="">
                            <img className="cercle_img vscroll vscrolling" src="images/circularimg.png" alt="circularimg" />
                          </picture>
                          <Slider {...sliderSettings} className="ourrum_textslider">
                            <div className="content">
                              <picture className="sideimage2 vscroll vscrolling2">
                                <img src="images/slide-1.png" alt="slide-1" />
                              </picture>
                              <picture className="image vscroll vscrolling">
                                <img src="images/image-1.gif" alt="image Gif" />
                              </picture>
                              <div className="details">
                                <h3 className="site-color">APPEARANCE</h3>
                                <p>
                                  A vibrant and deep amber coloured rum, with evident viscosity.
                                </p>
                              </div>
                            </div>
                            <div className="content">
                              <picture className="sideimage2 vscroll vscrolling2">
                                <img src="images/slide-top2.png" alt="slide-top2" />
                              </picture>
                              <picture className="image vscroll vscrolling">
                                <img src="images/slide-2.png" alt="slide-2" />

                              </picture>
                              <div className="details">
                                <h3 className="site-color">NOSE</h3>
                                <p>
                                  A captivating aroma, with initial notes of jaggery and gentle hints of vanilla and allspice.  As it opens up, subtle nuances of toasted oak and ripe fruits, such as raisins and plums, come to the fore.
                                </p>
                              </div>
                            </div>
                            <div className="content">
                              <picture className="sideimage2 vscroll vscrolling2">
                                <img src="images/slide-top3.png" alt="slide-top3" />
                              </picture>
                              <picture className="image vscroll vscrolling">
                                <img src="images/slide-3.png" alt="slide-3" />
                              </picture>
                              <div className="details">
                                <h3 className="site-color">PALATE</h3>
                                <p>
                                  On the tip of the palate, the caramel notes come through prominently, followed by delicious notes of dark chocolate, jaggery and tropical fruits.
                                  This is followed by hints of dried fruits, allspice, and toasted vanilla and oak.
                                </p>
                              </div>
                            </div>
                            <div className="content">
                              <picture className="sideimage2 vscroll vscrolling2">
                                <img src="images/slide-top4.png" alt="slide-top 4" />
                              </picture>
                              <picture className="image vscroll vscrolling">
                                <img src="images/slide-5.gif" alt="slide-5 gif" />

                              </picture>
                              <div className="details">
                                <h3 className="site-color">FINISH</h3>
                                <p>
                                  Smokey, long, lingering, dry and complex, with dry fruits, and toasted vanilla and oak.
                                </p>
                              </div>
                            </div>

                          </Slider>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="horizontal_moutain_1 mountain">
                    <img src="images/mountains.png" alt="mountains" />
                  </div>
                </section>

                <section className="section6 page-section">
                  <div className="container">
                    <div className="text-center">
                      <h3 className="h2 site-color text-uppercase animatetext">
                        THE HIMALAYAN SIPPING RUM,<br /> BEST ENJOYED IN GOOD COMPANY.
                      </h3>
                      <p className="font-24">
                        Sip your glass of IDAAYA with these delectable savoury <br></br>{" "}
                        pairings for an elevated drinking experience.
                      </p>
                      <div className="v-line small-line mt-40">
                        <svg
                          className="sc-16mwcw0-0 iZNjkc"
                          width="2"
                          height="283"
                          viewBox="0 0 2 283"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M1 0L0.999988 283" stroke="#7E573E" strokeWidth="2" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="section7 page-section">
                  <div className="container relative">
                    <div className="row align-item-center column-row">
                      <div className="column-4">
                        <picture className="vscrolling vscroll position-relative d-block">
                          <img src="images/pairing-image-1.png" alt="pairing" />
                        </picture>
                      </div>
                      <div className="column-6">
                        <div className="small-content-column">
                          <h3 className="site-color h3 mb-30">SEEKERS’ CHARCUTERIE</h3>
                          <p className="">
                            The ultimate rum grazing board, with savoury and sweet bites alike. Prosciutto and Salami Milano, sharp cheddar, peppered Gouda, grapes and berries, candied orange, dark chocolate and nuts.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row align-item-center column-row flex-row-reverse">
                      <div className="column-4">
                        <picture className="vscrolling2 vscroll position-relative d-block">
                          <img src="images/pairing-image-2.png" alt="pairing image" />
                        </picture>
                      </div>
                      <div className="column-6">
                        <div className="small-content-column">
                          <h3 className="site-color h3 mb-30">BROWN BUTTER & RUM CARAMELISED CARROTS</h3>
                          <p className="">
                            Carrots that have been roasted and drenched in our rum and brown butter. A quintessential addition to your meats.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row align-item-center column-row">
                      <div className="column-4">
                        <picture className="vscrolling vscroll position-relative d-block">
                          <img src="images/pairing-image-3.png" alt="pairing-image three" />
                        </picture>
                      </div>
                      <div className="column-6">
                        <div className="small-content-column">
                          <h3 className="site-color h3 mb-30">flambé PRAWNS WITH RUM & LIME</h3>
                          <p className=''>Prawns tossed generously with garlic, chilli and coriander, finished flambé style with rum. Served with a side of lime.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mountain1 clound-image-left  cloud-left" id="cl1">
                    <img src="images/cloud1.png" alt="cloud1" />
                  </div>
                  <div className="mountain2 clound-image2 cloud-right" id="ml0">
                    <img src="images/cloud2.png" alt="cloud2" />
                  </div>
                </section>

                <section className="section8 page-section">
                  <div className="container relative">
                    <div className="row align-item-center column-row flex-row-reverse">
                      <div className="column-4">
                        <picture className="vscrolling2 vscroll position-relative d-block">
                          <img src="images/pairing-image-4.png" alt="pairing-image four" />
                        </picture>
                      </div>
                      <div className="column-6">
                        <div className="small-content-column">
                          <h3 className="site-color h3 mb-30">Roasted Lamb with OUR SIGNATURE Rum Glaze</h3>
                          <p>
                            Roasted lamb dressed with a glaze of rum, dijon, rosemary, cinnamon and nutmeg — our preferred flavours.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row align-item-center column-row">
                      <div className="column-4">
                        <picture className="vscrolling vscroll position-relative d-block">
                          <img src="images/pairing-image-5.png" alt="pairing-image-5" />
                        </picture>
                      </div>
                      <div className="column-6">
                        <div className="small-content-column">
                          <h3 className="site-color h3 mb-30">RUM-DRENCHED TIRAMISU WITH ORANGE & NOIR</h3>
                          <p>
                            Layers of sponge finger, soaked in rum and cafe, meet creamy mascarpone that has been delicately sprinkled with orange zest and finished with dark chocolate.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="v-line line-big">
                    <svg
                      className="sc-16mwcw0-0 iZNjkc"
                      width="2"
                      height="283"
                      viewBox="0 0 2 283"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M1 0L0.999988 283" stroke="#7E573E" strokeWidth="2" />
                    </svg>
                  </div>
                  <div className="mountain1 clound-image  cloud-left" id="cloud1">
                    <img src="images/cloud1.png" alt="cloud1" />
                  </div>
                  <div className="mountain2 clound-image2 cloud-right" id="ml1">
                    <img src="images/cloud2.png" alt="cloud2" />
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default OurrumBanner;
