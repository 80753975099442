import React, { useRef, useEffect, useState} from 'react';
import { useParallax } from "react-scroll-parallax";
import RootsBottle from "../components/RootsBottle";
import animatetext from "../js/animatetext.js";
import main from "../js/main.js";


const OurRoots = () => {
	useEffect(() => {
        const timeoutId = setTimeout(() => {
            const line = document.querySelector('.visible_hide');
            if (line) {
                line.style.visibility = 'visible';
            }
        }, 2500);
        return () => clearTimeout(timeoutId);
    }, []);


	useEffect(() => {
		const timeoutId = setTimeout(() => {
            const rootsBanner = document.querySelector('.roots-banner');
            if (rootsBanner) {
                rootsBanner.classList.add('active_page'); 
            }
        }, 0); 
        return () => clearTimeout(timeoutId);
    }, []);

	useEffect(()=>{
		animatetext();
	  },[]);

	useEffect(() => { 
		$(document).ready( function() {
			$('.main-content').addClass('activetitle');
		  });
		var sections = $('section')
$(window).on('scroll', function() {
    var cur_pos = $(this).scrollTop();
    sections.each(function() {
        var top = $(this).offset().top -200,
            bottom = top + $(this).outerHeight();
        if (cur_pos >= top && cur_pos <= bottom) {
            //nav.find('a').removeClass('active');
            // sections.removeClass('active');
            $(this).addClass('activetitle');
        }
    });
});
}, []);

	useEffect(() => {
		const emblemInit = (el, str) => {
		  const element = document.querySelector(el);
		  if (element) {
			const text = str ? str : element.innerHTML;
			element.innerHTML = '';
			for (let i = 0; i < text.length; i++) {
			  const letter = text[i];
			  const span = document.createElement('span');
			  const node = document.createTextNode(letter);
			  const r = (360 / text.length) * i;
			  const x = (Math.PI / text.length).toFixed(0) * i;
			  const y = (Math.PI / text.length).toFixed(0) * i;
			  span.appendChild(node);
			  span.style.webkitTransform = `rotateZ(${r}deg) translate3d(${x}px,${y}px,0)`;
			  span.style.transform = `rotateZ(${r}deg) translate3d(${x}px,${y}px,0)`;
			  element.appendChild(span);
			}
		  }
		};
	
		emblemInit('.emblem');
	  }, []);

    return (
        <div className='ourroot-page'>
	<div className="roots-banner">
	    <picture className="anim1 root-mountain1 animate-mountain vscroll"><img src="images/mountain-1.svg" alt="mountain-1"/></picture>
	    <picture className="anim2 root-mountain2 animate-mountain vscroll"><img src="images/mountain-2.svg" alt="mountain-2"/></picture>
	</div>
    <div className="main" id="container1">
    	
    	<section className="main-content roots page-section" id="container2">
		    <section className="our-roots-banner">		    	
		    	<div className="banner-content animate-text container2">
		    		<h2 className="h2 site-color new-fade-anime text-uppercase mt-40">
					Inspired by the ancient<br/> Indian wisdom found in<br/> the Arthashastra,<br/> we pay homage to the origins<br/> of rum itself.
					</h2>
		    	</div>

		    </section>
		</section>
	</div>


	<section className="main-inside our-root visible_hide">		
		<div className="secion1">
		
			<div className="v-line small-line"> <svg
                className="sc-16mwcw0-0 iZNjkc"
                width="2"
                height="283"
                viewBox="0 0 2 283"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1 0L0.999988 283" stroke="#7E573E" strokeWidth="2" />
              </svg> </div>
			    
			<div className="">
				<div className="text-center secion1_content">
					<p className='font-24'>Our story starts in 300 AD, where we find,<br/>
in the forgotten pages of our ancient Indian scriptures,<br/>
the earliest mention of a fermented drink<br/>
made from sugarcane juice, called Amlasidhu.</p>

				<p className='font-24'>Rooted in this knowledge of authentic rum-making<br/>
inspired by the Arthashastra and the Solera system,<br/>
IDAAYA is a coming together of centuries-old, trusted practices.</p>
					
				</div>
				
			</div>
		</div>
	</section>
<RootsBottle/>
	<section className="roots-section-3 page-section">
		<div className="dark-snow"><img src="images/dark-snow.png" alt="dark snow"/></div>
		<div className="container relative">
					<h2 className="h2 site-color text-center text-uppercase new-fade-anime mb-3vw">
					THE HIMALAYAS IN EVERY SIP.
					</h2>
				<div className="v-line"> 
					<svg
					className="sc-16mwcw0-0 iZNjkc"
					width="2"
					height="283"
					viewBox="0 0 2 283"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path d="M1 0L0.999988 283" stroke="#7E573E" strokeWidth="2" />
				</svg>
			  </div>
				<div className="small-container text-center white-text">
						<p>Incomparable in scale and inimitable in soul,<br />  the mountains are intrinsic to the character of our rum.<br />
Our name amalgamates the mythological word ‘IDA’<br />with the ‘YA’ from Himalaya.</p>
					<p>Through the use of resources found only in the lap of<br />the Himalayas — high mountain altitude, crisp cool air and <br />Himalayan spring water — we blend the time-honoured and<br />the contemporary to create a memorable drinking experience.</p>
				</div>
		</div>	
	</section>
	</div>
  );
};

export default OurRoots;
