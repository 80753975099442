import React, { useState, useEffect } from "react";

const PrivacyPolicy = () => {
	useEffect(() => {
		$(document).ready( function() {
			$('.terms_page').addClass('activetitle');
		  });
		  
		var sections = $('section')
$(window).on('scroll', function() {
    var cur_pos = $(this).scrollTop();
    sections.each(function() {
        var top = $(this).offset().top -280,
            bottom = top + $(this).outerHeight();
        if (cur_pos >= top && cur_pos <= bottom) {
            //nav.find('a').removeClass('active');
            // sections.removeClass('active');
            $(this).addClass('activetitle');
        }
    });
});
}, []);

  return (
    <div className="termspage">
      <section className="main terms_page about-us" id="container1">    	
    	<div className="main-content">
		    <div className="about-us-banner">		    	
		    	<div className="container2_">
		    		<h1 className="h2 site-color text-uppercase animatetext text-center">
					INFORMATION NOTE ON THE <br/> PROCESSING OF PERSONAL<br/> DATA & COOKIES  
						</h1>
		    	</div>
		    </div>
		</div>
	</section>

	<div className="vertical_moutain_1 terms_moutain_1"><img src="images/side-mountains-1.png" alt="side mountain"/> <img src="images/side-mountains-1.png" alt="side mountain"/></div>
	<div className="vertical_moutain_2 terms_moutain_2"><img src="images/side-mountains-2.png" alt="side mountain right"/><img src="images/side-mountains-2.png" alt="side mountain right"/></div>		

	<section className="main-inside about-us privacy_lastsec termslat_sec">
		
		<div className="secion1 small-container">
			<div className="v-line small-line"> <svg
                className="sc-16mwcw0-0 iZNjkc"
                width="2"
                height="283"
                viewBox="0 0 2 283"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1 0L0.999988 283" stroke="#7E573E" strokeWidth="2" />
              </svg></div>
			<div className="">
				<div className="small-container">
				<h4 className="h4"><strong>Last update:</strong> October 2023</h4>

			<p>We are committed to taking care of your privacy. We respect your concerns about the protection of your privacy and your personal data. This Note on the processing of personal data and cookies (the "Note") sets out how we will treat your personal data on the Eminente website (hereinafter “the Site”, english version).</p>

			<p>For the purpose of this Note the words "we"/"us"/"our" are in reference to the data controllers defined below.</p>

			<h4 className="h4"><strong>Amendments</strong></h4>

			<p>We may update this privacy note from time-to-time by posting a new version on our Site. You should check this page occasionally to ensure you are informed of such changes.<br/>
			You will be informed of any substantial changes, either by means of a banner on the Site summarising the main changes made, or by e-mail.</p>

			<p>If you have any questions about this privacy note or our processing of your personal, you can:</p>
			<ul><li>write to us at "DPO Moët Hennessy, 38 rue de Sèvres, 75007 Paris, France"; or</li>
			<li>send us an email (contactdpo@moethennessy.com)</li>
			</ul>

			<h4 className="h4">What are the roles and responsibilities of our companies with regard to the processing of your personal data?</h4>

			<p>The processing of personal data operated on www.eminente.com (the "Site") is operated as joint data controllers within the meaning of the applicable personal data regulations (which includes the General Data Protection Regulation "GDPR" of 27 April 2016), by:</p>
			<ul><li>Rum Enterprise, registered in France with company number 879 041 770 ("Eminente" or "we") and whose registered office is at 142, rue du Bac-75007 Paris; and</li>
			<li>Moet Hennessy SAS (MH SAS), Société par Actions Simplifiée, registered in France with company number 338 228 414 RCS Paris and whose registered office is at 24-32 rue Jean Goujon, 75008 Paris, France.</li>
			</ul>

			<p>In addition unless otherwise stated, you may be in contact in your country of residence or in relation to one of the brands of the portfolio of Moet Hennessy or of the LVMH Group with one or more Moet Hennessy affiliates which, together with Eminente and Moet Hennessy SAS, have the status of joint data controller or independent data controller, as expressly mentioned in the Site's legal notice or in any other document sent to you.</p>

					
				</div>
				
			</div>
		</div>
	</section>
	</div>
  );
};

export default PrivacyPolicy;

