import { Link } from "react-router-dom";
import ScrollUpButton from "../components/ScrollUpButton"

const Footer = ({

}) => {
  return (
    <>
	<ScrollUpButton />
     <footer className="footer">
    	<div className="container">
			<h2 className="h1">KEEP SEEKING</h2>
	    	<div className="row">
				<div className="footer_left">
					<p>Stay updated on events, giveaways<br/> and other adventures.</p>
					<input className="" tyle="text" placeholder="EMAIL ID"/>
				</div>
				<div className="footer_right">
					<div className="footer_nav">
						<ul className="navbar">
							<li><Link to="/aboutus">About US</Link></li>
							<li><Link to="/barLocator">Find US</Link></li>
							<li><Link to="/press">Press</Link></li>
						</ul>
						<ul className="navbar">
							<li><Link to="/termsconditions">Terms & Conditions</Link></li>
							<li><Link to="/privacypolicy">Privacy Policy</Link></li>
							{/* <li><Link to="/barLocator">BAR LOCATOR</Link></li> */}
						</ul>
					</div>
				</div>
			</div>
			<div className="footer_bottom">
				<ul className="navbar">
						<li><Link to="/">CONTACT</Link></li>
						<li><Link to="/">INSTAGRAM</Link></li>
					</ul>
					<p>© COPYRIGHT IDAAYA RUM 2024</p>
			</div>
	    </div>
    </footer>
    </>
  );
};

export default Footer;
