import React, { useState } from "react";
import MediaQuery from 'react-responsive'

const Imagesplit = () => {
  const [isTransition, setIsTransition] = useState(false);
  const [displayContent, setDisplayContent] = useState(false);

  const handleToggle = () => {
    setIsTransition((prevIsTransition) => !prevIsTransition);

    if (!isTransition) {
      setTimeout(() => {
        setDisplayContent(true);
      }, 500);
    } else {
      setDisplayContent(false);
    }
  };

  const transitionClass = isTransition ? 'transition' : '';
  const contentStyle = displayContent ? { display: 'block' } : { display: 'none' };


  return (
    <>
     <MediaQuery minWidth={768}>
        {(matches) => matches ? (
          <section className="home-secion5">
          <div className="container">
            <ul className={`cards-split cards-down ${transitionClass}`}>
              <li className="card card-1" onClick={handleToggle}>
                <img src="images/card1.png" alt="card 1" />
                <div className="content" style={contentStyle}>
                  <h3 className="h4">INHERENTLY INDIAN</h3>
                  <p>Our rum borrows from ancient<br/> Indian wisdom and practices of liquid<br/> crafting found in early mentions of<br/> the Arthashastra from the third century.</p>
                </div>
              </li>
              <li className="card card-2" onClick={handleToggle}>
                <img src="images/card4.png" alt="card 2" />
                <div className="content" style={contentStyle}>
                  <h3 className="h4">A DOORWAY TO DISCOVERY</h3>
                  <p>Beyond mere product, IDAAYA is <br/>an experience — a mystery waiting <br/>to be unravelled, a space fostering <br/>curiosity, contemplation and revelation.</p>
                </div>
              </li>
              <li className="card card-4" onClick={handleToggle}>
                <img src="images/card2.png" alt="card 4" />
                <div className="content" style={contentStyle}>
                  <h3 className="h4">CAREFULLY CRAFTED</h3>
                  <p>We craft our rum in small batches — <br/>unhurriedly, and uncompromisingly.</p>
                </div>
              </li>
              <li className="card card-3" onClick={handleToggle}>
                <img src="images/card3.png" alt="card 3" />
                <div className="content" style={contentStyle}>
                  <h3 className="h4">TRUE TO<br/> TASTE</h3>
                  <p>Mindful of our mission of preserving<br/>
  the essence of rum-making,<br/> IDAAYA is kept free of artificial flavour, <br/>with no added sugar.</p>
                </div>
              </li>
             
            </ul>
          </div>
             
        </section>
        ) : null}
      </MediaQuery>

      <MediaQuery maxWidth={767}>
        {(matches) => matches ? (
         <section className="home-secion5">
         <div className="container">
           <ul className={`cards-split cards-down ${transitionClass}`}>
             <li className="card card-1" onClick={handleToggle}>
             <img src="images/card1mob.webp" alt="card 1" />
               <div className="content" style={contentStyle}>
                 <h3 className="h4">INHERENTLY INDIAN</h3>
                 <p>Our rum borrows from ancient<br/> Indian wisdom and practices of liquid<br/> crafting found in early mentions of<br/> the Arthashastra from the third century.</p>
               </div>
             </li>
             <li className="card card-2" onClick={handleToggle}>
               <img src="images/card4mob.webp" alt="card 2" />
               <div className="content" style={contentStyle}>
                 <h3 className="h4">A DOORWAY TO DISCOVERY</h3>
                 <p>Beyond mere product, IDAAYA is <br/>an experience — a mystery waiting <br/>to be unravelled, a space fostering <br/>curiosity, contemplation and revelation.</p>
               </div>
             </li>
             <li className="card card-4" onClick={handleToggle}>
               <img src="images/card2mob.webp" alt="card 4" />
               <div className="content" style={contentStyle}>
                 <h3 className="h4">CAREFULLY CRAFTED</h3>
                 <p>We craft our rum in small batches — <br/>unhurriedly, and uncompromisingly.</p>
               </div>
             </li>
             <li className="card card-3" onClick={handleToggle}>
               <img src="images/card3mob.webp" alt="card 3" />
               <div className="content" style={contentStyle}>
                 <h3 className="h4">TRUE TO<br/> TASTE</h3>
                 <p>Mindful of our mission of preserving<br/>
 the essence of rum-making,<br/> IDAAYA is kept free of artificial flavour, <br/>with no added sugar.</p>
               </div>
             </li>
            
           </ul>
         </div>
            
       </section>
        ) : null}
      </MediaQuery>

      
    </>
  );
};

export default Imagesplit;
